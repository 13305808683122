import { useQuery } from '@apollo/client'
import moment from 'moment';
import { useEffect, useState } from 'react';
import { appTimeZone } from '../../../globals';
import { GET_ALL_METERS_STATUS } from '../../../queriesAndMutations/queries/meter.queries';

import { Meter } from '../../../utils/typedefs';
import Modal from '../../../components/modals/Modal';
import WebhookMessageViewer from './WebhookMessageViewer';

const DeviceStatus = () => {
  const pollingTime = 10000;
  const allMeters = useQuery<any>(GET_ALL_METERS_STATUS, { 
    onCompleted(data) {
      !searchValues && setSearchValues(data?.allMetersForDeviceStatus)
      searchValues && setSearchValues(searchValues?.flatMap((searchMeter: Meter) => { return data?.allMetersForDeviceStatus?.filter((meter: Meter) => meter._id === searchMeter._id) }).flat());

    },
    fetchPolicy: "network-only",
    pollInterval: pollingTime 
  });
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [searchValues, setSearchValues] = useState<Array<Meter>>();
  const [showWebhookMessageModal, setShowWebhookMessageModal] = useState<boolean>(false);
  const [webhookMessage, setWebhookMessage] = useState<string>("");

  //currently polling aler history every 10 seconds
  allMeters.startPolling(pollingTime);


  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
        window.removeEventListener("scroll", handleScroll);
    };
}, [scrollPosition]);

const handleScroll = () => {
  setScrollPosition(window.pageYOffset || window.pageYOffset || window.scrollY);
};

const handleSearchChange = (searchValue: any) => {
  setSearchValues(allMeters?.data?.allMetersForDeviceStatus?.filter((meter: Meter) =>
    meter.meterNumber.toUpperCase().includes(searchValue.toUpperCase()) ||
    meter.battery.toUpperCase().includes(searchValue.toUpperCase()) ||
    meter.deviceEUI.toUpperCase().includes(searchValue.toUpperCase())
  ))

};

const handleWebhookMessageView = (webhookMessage: string) => {
  setWebhookMessage(webhookMessage);
  setShowWebhookMessageModal(true);
}

  return (
    <>
      {/* /////// DEVICESTATUS Table ////// */}
      <div className='card flex flex-col items-center'>
        <h2 className="text-xl font-bold text-primary my-6">Device (Pulse Counter) Status</h2>
        <div>
          <h1 className='text-sm mb-2 '>Search by DeviceEUI, Meter Number or Battery:</h1>
          <input
            placeholder='Search...'
            type={"text"}
            disabled={allMeters?.loading}
            className="block w-full mb-12 pr-8 transition border-2 border-gray-300 rounded-md shadow-sm hover:bg-secondary/10 focus:border-secondary focus:ring focus:ring-secondary/50 focus:ring-opacity-50 bg-secondary/5 disabled:cursor-not-allowed"
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>
        <table className="min-w-full device-status-table">
          <thead>
            <tr>
              <th>Meter Number</th>
              <th>DeviceEUI</th>
              <th>Gateway(s)</th>
              <th>Battery Level</th>
              <th>Last Webhook Received</th>
              <th>Webhook Message</th>
            </tr>
          </thead>
          <tbody>
            {allMeters?.loading && <tr id='loading'>
              <td>
                Loading Device Data...
              </td>

            </tr>
            }
            {!allMeters.loading && searchValues?.map((meter: Meter, idx: number) => {
              return(
              <tr key={idx} className="my-2 odd:bg-secondary/5 hover:bg-secondary/10">
                 <td className='text-left'>
                  {meter?.meterNumber}
                </td>
                <td className='text-left'>
                  {meter?.deviceEUI}
                </td>
                <td className='text-left'>
                  {meter?.gateways?.map((gw: any, gwIdx: number) => (
                    <div key={gwIdx}>
                      {gw.gatewayName}<br />
                      <blockquote style={{ marginTop: '0.5rem' }}>
                        RSSI: {gw.rssi}<br />
                        SNR: {gw.snr}
                      </blockquote>
                    </div>
                  ))}
                </td>
                <td className='text-left'>
                  {meter?.battery}%
                </td>
                <td className={`text-left ${moment().diff(meter?.lastWebhookReceivedAt, "minutes") > 60 ? "status-colour-red" : "status-colour-green"}`}
                >
                  {moment.tz(meter?.lastWebhookReceivedAt,appTimeZone).format("DD/MM/YYYY HH:mm:ss")}<br />
                  {moment.tz(meter?.lastWebhookReceivedAt, appTimeZone).fromNow(false)}<br />
                </td>
                <td className='text-left'>
                 {meter?.lastWebhookMessage ? <button
                    onClick={() => handleWebhookMessageView(meter?.lastWebhookMessage)}
                    className="button-base mt-2 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray "
                    type="button" 
                  >
                    View
                  </button> :
                  <span>None</span>}
                </td>
              </tr>
            )})}
          </tbody>
        </table>
        <button
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }}
          className="fixed p-2 text-sm text-black bg-blue-500/25 bottom-1 right-1 rounded scroll-smooth"
          style={{ display: scrollPosition <= 800 ? "none" : "unset" }}
        >
          ^ Top
        </button>
      </div>
      {showWebhookMessageModal &&
        <Modal altButtonText={"Close"} isOpen={showWebhookMessageModal} onClose={setShowWebhookMessageModal}>
          <WebhookMessageViewer webhookMessage={webhookMessage} />
        </Modal>}
    </>
  )

}

export default DeviceStatus
