import { useQuery } from '@apollo/client'
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReadingCreateInput from '../../components/graphQLInputs/createInputs/ReadingCreateInput';
import { appTimeZone, IMessage, messageTimerDisplayTime } from '../../globals';
import { GET_ALL_READINGS } from '../../queriesAndMutations/queries/readings.queries';

import { MeterReading } from '../../utils/typedefs';




const MeterReadings = () => {

  const meterReadings = useQuery<any>(GET_ALL_READINGS, {
    fetchPolicy: "network-only",

    onCompleted(data) {
      searchValues && setSearchValues(data?.getAllReadings);
    }
  });

  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [searchValues, setSearchValues] = useState<Array<MeterReading>>([]);
  const [updateResponse, setUpdateResponse] = useState<IMessage>({ text: "", colour: "red" });




  const startMessageTimer = () => {
    setTimeout(() => {
      setUpdateResponse && setUpdateResponse({ text: "", colour: "" });
    }, messageTimerDisplayTime);
  };

  useEffect(() => {
    if (updateResponse.text) {
      if (!updateResponse.text.includes("Please wait...")) {
        startMessageTimer();
      }
    }
  }, [updateResponse])



  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);



  const handleScroll = () => {
    setScrollPosition(window.pageYOffset || window.scrollY);
  };



  const handleSearchChange = (searchValue: any) => {
    setSearchValues(meterReadings?.data?.getAllReadings?.filter((reading: MeterReading) =>
      reading.meterNumber.toLocaleUpperCase().includes(searchValue.toUpperCase()) ||
      reading.createdBy.toUpperCase().includes(searchValue.toUpperCase()) ||
      reading.readingDatetime.toString().toUpperCase().includes(searchValue.toUpperCase())

    ));
  }


  return (
    <>
        <div className='card flex flex-col items-center mb-16'>
        <ReadingCreateInput isAdminDash formId='add-reading' setUpdateResponse={setUpdateResponse} />
        {updateResponse.text && <div className={`w-full text-lg my-4 bg-slate-200 flex flex-col items-center align-center`}>
          <h1 style={{ color: updateResponse.colour }} className='p-2'>{updateResponse.text}</h1>
        </div>}
      </div>
      {/* /////// METER READINGS Table ////// */}
      <div className='card flex flex-col items-center'>
        <h2 className="text-xl font-bold text-primary my-6">Meter Readings</h2>
        <div>
          <h1 className='text-sm mb-2 '>Search by Meter Number, Date/Time or Created By:</h1>
          <input
            placeholder='Search...'
            type={"text"}
            disabled={meterReadings?.loading}
            className="block w-full mb-12 pr-8 transition border-2 border-gray-300 rounded-md shadow-sm hover:bg-secondary/10 focus:border-secondary focus:ring focus:ring-secondary/50 focus:ring-opacity-50 bg-secondary/5 disabled:cursor-not-allowed"
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>
        <table className="min-w-full meter-reading-table">
          <thead>
            <tr>
              <th>Meter Number</th>
              <th>Meter Value</th>
              <th>Date/Time</th>
              <th>Created by:</th>
            </tr>
          </thead>
          <tbody>
            {meterReadings?.loading && <tr id='loading'>
              <td>
                Loading Meter Readings...
              </td>

            </tr>
            }
            {!meterReadings.loading && searchValues?.map((reading: MeterReading) => (
              <tr key={reading._id.toString()} className="my-8 odd:bg-secondary/5 hover:bg-secondary/10">
                <td className='text-left'>
                  {reading?.meterNumber}
                </td>
                <td className='text-left'>
                  {reading?.readingValue}
                </td>
                <td className={"text-left"}>
                  {moment.tz(reading?.readingDatetime, appTimeZone).format("DD/MM/YYYY HH:mm:ss")}<br />
                </td>
                <td className='text-left'>
                  {reading?.createdBy}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }}
          className="fixed p-2 text-sm text-black bg-blue-500/25 bottom-1 right-1 rounded scroll-smooth"
          style={{ display: scrollPosition <= 800 ? "none" : "unset" }}
        >
          ^ Top
        </button>
      </div>
    </>
  )

}

export default MeterReadings