import { useEffect, useState } from 'react';
import { IMessage, appTimeZone, messageTimerDisplayTime } from '../../globals';
import { useLazyQuery } from '@apollo/client';
import { CHECK_ALERTS } from '../../queriesAndMutations/queries/alert.queries';
import moment from 'moment';
import { useGetAlertSpec } from '../../hooks/useGetAlertSpec';


const AlertsCheck = () => {
  const [alertResults, setAlertResults] = useState<any>(null);
  const { alertSpecToUsableString } = useGetAlertSpec();
  const [checkAlerts, { loading, error, data: alertsData, called: hasRun }] = useLazyQuery<any>(CHECK_ALERTS, {
    fetchPolicy: 'network-only',
    onCompleted(data: any){
      setAlertResults((prev: any) => {
        const updatedPrev = prev || {alertsCheck: []};
        return {
          alertsCheck: data.alertsCheck.length ? [...updatedPrev.alertsCheck, ...data.alertsCheck] : updatedPrev.alertsCheck
        }
      })
    }
  });

  
  return (
      <div className='card flex flex-col items-center'>
        <h2 className="text-xl font-bold text-primary my-6">Alerts Check</h2>
        <div className="card-footer">
          <div className="p-4 px-6">
            <button
              className="button-base disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray "
              type="submit"
              disabled={loading}
              onClick={() => checkAlerts()}
            >
              Run Alerts check
            </button>
          </div>
        </div>
        {alertResults?.alertsCheck?.length > 0 && <table className="min-w-full meter-reading-table">
          <thead>
            <tr>
              <th>Alert Trigger</th>
              <th>Meter Number</th>
              <th>Trigger Volume</th>
              <th>Date/Time</th>
            </tr>
          </thead>
          <tbody>
            {alertResults?.alertsCheck?.map((alertHist: any) => (
              <tr key={alertHist._id.toString()} className="my-8 odd:bg-secondary/5 hover:bg-secondary/10">
                <td className='text-left'>
                  {alertSpecToUsableString(alertHist?.alert?.alertSpec)}
                </td>
                <td className='text-left'>
                {alertHist?.alert?.meterName}
                </td>
                <td className='text-left'>
                  {alertHist?.alert?.volume.toString()}
                </td>
                <td className={"text-left"}>
                  {moment.tz(alertHist?.triggerTime, appTimeZone).format("DD/MM/YYYY HH:mm:ss")}<br />
                </td>
              </tr>
            ))}
          </tbody>
        </table>}
        {loading && <div id='loading' className='my-8'>
          Running Alert Triggers...
        </div>
        }
        {error && <div id='loading' className='my-8'>
          Error: {error?.message}
        </div>
        }
        {!loading && alertResults?.alertsCheck?.length === 0 && hasRun && <div id='loading' className='my-8'>
          No alerts triggered
        </div>
        }
      </div>
  )

}

export default AlertsCheck