import React, { useState } from 'react'
import { LastWebhookMessage } from './WebhookMessageInterface';


type Props = {
  webhookMessage: string;
}

const WebhookMessageViewer: React.FC<Props> = ({ webhookMessage }) => {
  const parsedMessage: LastWebhookMessage = JSON.parse(webhookMessage);
  return (
    <div className="overflow-auto max-h-screen mb-12 card-topper p-4 bg-white rounded shadow w-full">
      <h1 className="text-2xl font-bold mb-4">End Device IDs</h1>
      <p><strong>Device ID:</strong> {parsedMessage.end_device_ids.device_id}</p>
      <p><strong>Application ID:</strong> {parsedMessage.end_device_ids.application_ids.application_id}</p>
      <p><strong>Dev EUI:</strong> {parsedMessage.end_device_ids.dev_eui}</p>
      <p><strong>Join EUI:</strong> {parsedMessage.end_device_ids.join_eui}</p>
      <p><strong>Dev Addr:</strong> {parsedMessage.end_device_ids.dev_addr}</p>

      <h1 className="text-2xl font-bold mt-6 mb-4">Correlation IDs</h1>
      <ul className="list-disc list-inside">
        {parsedMessage.correlation_ids.map((id, index) => (
          <li key={index}>{id}</li>
        ))}
      </ul>

      <h1 className="text-2xl font-bold mt-6 mb-4">Received At</h1>
      <p>{parsedMessage.received_at}</p>

      <h1 className="text-2xl font-bold mt-6 mb-4">Uplink Message</h1>
      <p><strong>Session Key ID:</strong> {parsedMessage.uplink_message.session_key_id}</p>
      <p><strong>F Port:</strong> {parsedMessage.uplink_message.f_port}</p>
      <p><strong>F Cnt:</strong> {parsedMessage.uplink_message.f_cnt}</p>
      <p><strong>Frm Payload:</strong> {parsedMessage.uplink_message.frm_payload}</p>

      <h2 className="text-xl font-semibold mt-4 mb-2">RX Metadata</h2>
      {parsedMessage.uplink_message.rx_metadata.map((metadata, index) => (
        <div key={index} className="mb-4 p-2 border border-gray-200 rounded">
          <h3 className="font-semibold">Gateway ID: {metadata.gateway_ids.gateway_id}</h3>
          <p><strong>Gateway EUI:</strong> {metadata.gateway_ids.eui}</p>
          <p><strong>Time:</strong> {metadata.time}</p>
          <p><strong>Timestamp:</strong> {metadata.timestamp}</p>
          <p><strong>RSSI:</strong> {metadata.rssi}</p>
          <p><strong>Channel RSSI:</strong> {metadata.channel_rssi}</p>
          <p><strong>SNR:</strong> {metadata.snr}</p>
          <p><strong>Uplink Token:</strong> {metadata.uplink_token}</p>
          <p><strong>Received At:</strong> {metadata.received_at}</p>
          {metadata.frequency_offset && <p><strong>Frequency Offset:</strong> {metadata.frequency_offset}</p>}
          {metadata.channel_index !== undefined && <p><strong>Channel Index:</strong> {metadata.channel_index}</p>}
          {metadata.gps_time && <p><strong>GPS Time:</strong> {metadata.gps_time}</p>}
        </div>
      ))}

      <h2 className="text-xl font-semibold mt-4 mb-2">Settings</h2>
      <p><strong>Frequency:</strong> {parsedMessage.uplink_message.settings.frequency}</p>
      <p><strong>Timestamp:</strong> {parsedMessage.uplink_message.settings.timestamp}</p>
      <p><strong>Time:</strong> {parsedMessage.uplink_message.settings.time}</p>
      <h3 className="font-semibold">Data Rate</h3>
      <p><strong>Bandwidth:</strong> {parsedMessage.uplink_message.settings.data_rate.lora.bandwidth}</p>
      <p><strong>Spreading Factor:</strong> {parsedMessage.uplink_message.settings.data_rate.lora.spreading_factor}</p>
      <p><strong>Coding Rate:</strong> {parsedMessage.uplink_message.settings.data_rate.lora.coding_rate}</p>

      <h1 className="text-2xl font-bold mt-6 mb-4">Network IDs</h1>
      <p><strong>Net ID:</strong> {parsedMessage.uplink_message.network_ids.net_id}</p>
      <p><strong>NS ID:</strong> {parsedMessage.uplink_message.network_ids.ns_id}</p>
      <p><strong>Tenant ID:</strong> {parsedMessage.uplink_message.network_ids.tenant_id}</p>
      <p><strong>Cluster ID:</strong> {parsedMessage.uplink_message.network_ids.cluster_id}</p>
      <p><strong>Cluster Address:</strong> {parsedMessage.uplink_message.network_ids.cluster_address}</p>
      <p><strong>Tenant Address:</strong> {parsedMessage.uplink_message.network_ids.tenant_address}</p>
    </div>

  );
}

export default WebhookMessageViewer
