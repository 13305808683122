import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import * as Yup from 'yup'
import { Form, Formik, FormikProps } from 'formik'

// Import GraphQL queries and mutations

import FormikInput from '../../fields/FormikInput'
import { IMessage } from '../../../globals'
import "react-datepicker/dist/react-datepicker.css";
import { REGISTER_METER_AND_CUSTOMER } from '../../../queriesAndMutations/mutations/meter.mutations'
import { GET_ALL_METERCODES } from '../../../queriesAndMutations/queries/meter.queries'
import { GET_ALL_INVOICES } from '../../../queriesAndMutations/queries/invoices.queries'
import AutoCompleteList from '../../fields/AutoCompleteList'


type Props = {
  formId: string;
  setUpdateResponse: React.Dispatch<React.SetStateAction<IMessage>>;
}


type InitialValues = {
  meterNumber: string;
  deviceEUI: string;
  customerName: string;
}


const initialValues: InitialValues = {
  meterNumber: '',
  deviceEUI: '',
  customerName: '',
}

type AutoCompleteOptions = {
  showSuggestions: boolean;
  suggestions: Array<string>;
  suggestionSelected: boolean;
}
const autoCompleteDefaults: AutoCompleteOptions = {
  showSuggestions: false,
  suggestions: [],
  suggestionSelected: false,
}

const RegisterMeterCreateInput: React.FC<Props> = ({ formId, setUpdateResponse }) => {

  const [registerMeterAndCustomer] = useMutation<any>(REGISTER_METER_AND_CUSTOMER,{ 
    fetchPolicy: 'network-only',
    refetchQueries: [
      {
        query: GET_ALL_METERCODES,
      },
    ],
  });

  const [getAllInvoices, {loading: allInvoicesLoading}] = useLazyQuery<any>(GET_ALL_INVOICES,{ 
    fetchPolicy: "network-only",
    onCompleted(data) {
      console.log("data", data);
      setAutoCompleteState((prev) => {
        return {
          ...prev,
          suggestions: data?.getAllInvoices?.map((inv: any) => inv.customer),
          showSuggestions: Boolean(data?.getAllInvoices?.length)
        }
      });
      
    }
  });
  //state to handle edited values
  const [valuesToEdit, setValuesToEdit] = useState<InitialValues>(initialValues);
  const [autoCompleteState, setAutoCompleteState] = useState<AutoCompleteOptions>(autoCompleteDefaults);

  const validationSchema = Yup.object({
    customerName: Yup.string().required('Customer Name Required'),
    deviceEUI: Yup.string()
    .required('DeviceEUI Required')
    .min(16, "Must be 16 characters long")
    .max(16, "16 Characters maximum"),
    meterNumber: Yup.mixed()
    .required('Meter Number required')
    .test('type', "Must be a number", (value :any) => !isNaN(value))
  });

  const onSubmit = (values: any) => {

    setUpdateResponse && setUpdateResponse({ text: "Registering meter to customer. Please wait...", colour: "#2b679b" });

    console.log(values);
    
    registerMeterAndCustomer({
      variables: {
        "input": {
          meterNumber: values.meterNumber,
          customerName: values.customerName,
          deviceEUI: values.deviceEUI
        }
      },
    }).then((response: any) => {
      if (response.data) {
        setUpdateResponse && setUpdateResponse({ text: "Meter registered successfully!", colour: "green" });
        setDefaults();
      }
    })
      .catch((err: any) => {
        setUpdateResponse && setUpdateResponse({ text: err.message, colour: "red" });
      });;

  }

  useEffect(() => {
    // If customerName < 2 chars...
    if (valuesToEdit.customerName.length === 0) {
      // Reset autocomplete state
      setAutoCompleteState(autoCompleteDefaults);
      return;
    };
    // Wrapping code in a seTimeout to act as debounce...
    // to prevent multiple queries with each letter typed.

    const getData = setTimeout(() => {
      const customerLookup = async () => {
        await getAllInvoices({
          variables: {
            searchFor: valuesToEdit.customerName || ""
          }
        });
      };

      // Only query DB if not already loading a query
      if (!allInvoicesLoading && !autoCompleteState.suggestionSelected) {
        console.log("querying");
        customerLookup();
        return;
      }
    }, 800);
    // Cleanup function to destroy timer on completion
    return () => clearTimeout(getData);
  }, [valuesToEdit.customerName]);

  const handleAutoCompleteSelect = (e: any) => {
    console.log("Seleected", e?.target?.textContent);
    setValuesToEdit((prev) => {
      return { ...prev, customerName: e?.target?.textContent }
    });
    setAutoCompleteState((prev) => {
      return { suggestions: [], suggestionSelected: true, showSuggestions: false}
    });
  }

  const handleFormChange = (e: any) => {
    if(e.id === "customerName"){setAutoCompleteState((prev) => {
      return { ...prev, suggestionSelected: false}
    });}
    let val = (e.id == "deviceEUI") ? e.value?.toUpperCase() : e.value;
    setValuesToEdit((prev) => {
      return { ...prev, [e.id]: val }
    });
  }

  const setDefaults = () => {
    setValuesToEdit({
      meterNumber: '',
      deviceEUI: '',
      customerName: '',
    })
  }

  useEffect(() => {
    setDefaults();
  }, [])


  return (
    <Formik
      initialValues={valuesToEdit}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {(formik: FormikProps<any>) => {
        return (

          <div className="w-full my-12 card divide-primary/10">
            <div className="p-4 px-6 card-topper ">
              <h3 className="text-xl font-bold text-primary">

                Register New Meter
              </h3>
            </div>


            <Form id={formId} onChange={(e) => handleFormChange(e.target)}>
              <div className="card-content">
                <div className="flex flex-col justify-between p-4 px-6 space-y-4 md:space-x-8 md:flex-row md:space-y-0">
                  <FormikInput
                    label="Meter Number"
                    name="meterNumber"
                    onLoadValue={valuesToEdit.meterNumber}
                    isLoading={false}
                  />
                  <FormikInput
                    label="DeviceEUI"
                    name="deviceEUI"
                    onLoadValue={valuesToEdit.deviceEUI}
                    isLoading={false}
                  />
                 
                  <AutoCompleteList 
                    onSelected={handleAutoCompleteSelect} 
                    showSuggestions={autoCompleteState.showSuggestions}
                    suggestions={autoCompleteState.suggestions}
                    isLoading={allInvoicesLoading}
                    >
                    <FormikInput
                      label="Customer Name"
                      name="customerName"
                      onLoadValue={valuesToEdit.customerName}
                      isLoading={false}
                      fullWidth
                      {...{placeholder: "Search or create new..."}} 
                    />
                  </AutoCompleteList>
                </div>
              </div>
            </Form>

            <div className="card-footer">
              <div className="p-4 px-6">
                <button
                  form={formId}
                  className="button-base disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray "
                  type="submit"
                  disabled={valuesToEdit.deviceEUI.length < 16 || valuesToEdit.customerName.length === 0 || valuesToEdit.meterNumber.length < 4 || allInvoicesLoading}
                >
                  Register
                </button>
              </div>
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

export default RegisterMeterCreateInput
