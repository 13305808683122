export type Meter = {
  _id: String
  farm: Farm
  meterCode: String
  name: String
  userId: String
  meterNumber: string
  deviceEUI: string
  lastWebhookReceivedAt: Date
  lastWebhookMessage: string
  battery: string
  gateways: Array<Object>

}

export type Farm = {
  _id: String
  meters: [Meter]
  name: String
  size: Number
  userId: String
}

export type User = {
  _id: String
  authToken: String
  email: String
  password: String
  name: String
  verificationToken: String
}

export type Alert = {
  _id: String
  alertSpec: AlertSpec
  farm: Farm
  meter: Meter
  userId: String
  volume: Number
}

export type AlertHistory = {
  _id: String
  alert: Alert
  triggerTime: Date
}

export enum AlertSpec {
  ALL_DAY,
  AM2
}

export type MeterCode = {
  _id: string
  meterNumber: string
  uniqueMeterCode: string
  customerId: string
  customer: string
  deviceEUI: string
}

export type MeterReading = {
  _id: string
  meterNumber: string
  readingDatetime: Date
  readingValue: string
  createdBy: string
}

export type MeterValue = {
  _id: string
  meterNumber: string
  customer: {_id: string, meterNumber: string, customer: string}
  deviceEUI: string
  calculatedMeterReading: number
}

export enum UserRoles {
  USER = "USER",
  ADMIN ="ADMIN"
}



//RAW SAMPLE FROM GO API
export type RawSample = {
  _id: String
  webhook_message: {correlation_ids: Array<any>, received_at: Date}
  value: number
  delta: number
  deviceEUI: String
  timestamp: Date
}