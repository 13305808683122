import { useEffect, useState } from 'react';
import { IMessage, messageTimerDisplayTime } from '../../globals';

import RegisterMeterCreateInput from '../../components/graphQLInputs/createInputs/RegisterMeterCreateInput';


const NewMeterAndCustomer = () => {
  const [updateResponse, setUpdateResponse] = useState<IMessage>({ text: "", colour: "red" });

  const startMessageTimer = () => {
    setTimeout(() => {
      setUpdateResponse && setUpdateResponse({ text: "", colour: "" });
    }, messageTimerDisplayTime);
  };

  useEffect(() => {
    if (updateResponse.text) {
      if (!updateResponse.text.includes("Please wait...")) {
        startMessageTimer();
      }
    }
  }, [updateResponse])

  return (
    <>
      <div className='card flex flex-col items-center mb-16'>
        <RegisterMeterCreateInput formId='add-reading' setUpdateResponse={setUpdateResponse} />
        {updateResponse.text && <div className={`w-full text-lg my-4 bg-slate-200 flex flex-col items-center align-center`}>
          <h1 style={{ color: updateResponse.colour }} className='p-2'>{updateResponse.text}</h1>
        </div>}
      </div>
    </>
  )

}

export default NewMeterAndCustomer