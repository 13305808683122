import { gql } from '@apollo/client'


// **** METER RELATED QUERIES ****
export const GET_ALL_METERS = gql`
  query getAllMeters {
    meters {
      _id
      farm {_id name}
      name
      meterCode
      meterNumber
      deviceEUI
    }
  }
`
export const GET_ALL_METERS_STATUS = gql`
  query allMetersForDeviceStatus {
   allMetersForDeviceStatus {
            _id
  deviceEUI
  meterNumber
  battery
  gateways {
    _id
    gatewayId
    gatewayName
    rssi
    snr
    __typename
  }
  lastWebhookReceivedAt
  lastWebhookMessage
    }
  }
  `

export const GET_SINGLE_METER = gql`
  query getSingleMeter($_id: String!) {
    meter(_id: $_id) {
      _id
      farm { _id name  meters {_id name meterCode}}
      name
      meterCode
    }
  }
`


export const GET_ALL_METERCODES = gql`
 query getAllMeterCodes {
  getAllMeterCodes {
      _id
      meterNumber
      uniqueMeterCode
      deviceEUI
      customerId
      customer
    }
  }
`

export const GET_METER_VALUES = gql`
   query getAllMeterValues {
    getAllMeterValues {
      _id
      deviceEUI
      meterNumber
      customer {_id, customer}
      calculatedMeterReading
    }
  }
`