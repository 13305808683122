import { gql } from '@apollo/client'

// **** ALERT RELATED QUERIES ****

export const GET_ALERT_HISTORY = gql`
  query getAlertHistory {
    alertHistory {
      _id
      alert { 
        # _id 
        meter { _id name}
        farm { _id name }
        alertSpec
        volume
      }
      triggerTime
    }
  }
`

export const GET_ALL_ALERTS = gql`
  query getAllAlerts {
    alerts {
      _id
      meter {
        _id
        name
      }
      farm {
        _id
        name
      }
      alertSpec
      volume
    }
  }
`
export const GET_SINGLE_ALERT = gql`
  query getSingleAlert($_id: String!) {
    alert(_id: $_id) {
      _id
      meter {
        _id
        name
        meterCode
      }
      farm {
        _id
        name
      }
      alertSpec
    }
  }
`

export const CHECK_ALERTS = gql`
  query checkAlerts {
    alertsCheck{
        _id
        alert {_id userId alertSpec volume meterId meterName}
        triggerTime
        userId
   } 
  }
`