import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react';
import {  IMessage, messageTimerDisplayTime } from '../../globals';
import { MeterValue } from '../../utils/typedefs';
import { GET_METER_VALUES } from '../../queriesAndMutations/queries/meter.queries';




const MeterValues = () => {

  const meterValues = useQuery<any>(GET_METER_VALUES, {
    nextFetchPolicy: 'network-only', 
    
    onCompleted(data) {
      searchValues && setSearchValues(data?.getAllMeterValues);
    }
  });

  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [searchValues, setSearchValues] = useState<Array<MeterValue>>([]);




  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);



  const handleScroll = () => {
    setScrollPosition(window.pageYOffset || window.scrollY);
  };



  const handleSearchChange = (searchValue: any) => {
    setSearchValues(meterValues?.data?.getAllMeterValues?.filter((meterVal: MeterValue) =>
      meterVal.meterNumber.toLocaleUpperCase().includes(searchValue.toUpperCase()) ||
      meterVal.customer.customer.toUpperCase().includes(searchValue.toUpperCase()) ||
      meterVal.calculatedMeterReading?.toString().toUpperCase().includes(searchValue.toUpperCase()) 
    ));
  }


  return (
    <>
      {/* /////// MultiChoice Table ////// */}
      <div className='card flex flex-col items-center'>
        <h2 className="text-xl font-bold text-primary my-6">Meter Values</h2>
        <div>
          <h1 className='text-sm mb-2 '>Search by Meter Number or Customer:</h1>
          <input
            placeholder='Search...'
            type={"text"}
            disabled={meterValues?.loading}
            className="block w-full mb-12 pr-8 transition border-2 border-gray-300 rounded-md shadow-sm hover:bg-secondary/10 focus:border-secondary focus:ring focus:ring-secondary/50 focus:ring-opacity-50 bg-secondary/5 disabled:cursor-not-allowed"
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>
        <table className="min-w-full meter-values-table">
          <thead>
            <tr>
              <th>Meter Number</th>
              <th>Customer</th>
              <th>Calculated Value</th>
            </tr>
          </thead>
          <tbody>
            {meterValues?.loading && <tr id='loading'>
              <td>
                Loading Meter Values...
              </td>

            </tr>
            }
            {!meterValues.loading && searchValues?.map((meterVal: MeterValue) => (
              <tr key={meterVal._id.toString()} className="my-8 odd:bg-secondary/5 hover:bg-secondary/10">
                <td className='text-left'>
                  {meterVal?.meterNumber}
                </td>
                <td className='text-left'>
                  {meterVal?.customer.customer}
                </td>
                <td id="last" className='text-left'>
                <span className='text-black'>{meterVal.calculatedMeterReading ?  meterVal.calculatedMeterReading : ""}</span> 
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }}
          className="fixed p-2 text-sm text-black bg-blue-500/25 bottom-1 right-1 rounded scroll-smooth"
          style={{ display: scrollPosition <= 800 ? "none" : "unset" }}
        >
          ^ Top
        </button>
      </div>
    </>
  )

}

export default MeterValues