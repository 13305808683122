import React, { useEffect, useState } from 'react'
import NormalHeader from '../../components/headers/NormalHeader'
import SettingsAccount from './SettingsAccount'
import SettingsFarms from './SettingsFarms'
import SettingsMeters from './SettingsMeters'

type Props = {}

const Settings: React.FC<Props> = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset || window.pageYOffset || window.scrollY);
  };

  return (
    <div className="flex flex-col min-h-screen site-wrapper">
      <header>
        <NormalHeader title={'Settings'} />
      </header>

      <main>
        
        <SettingsAccount />

        <div className="hidden md:fluid-container md:block">
          <hr className="border-primary/20" />
        </div>
        <SettingsMeters />

        <div className="hidden md:fluid-container md:block">
          <hr className="border-primary/20" />
        </div>
        <SettingsFarms />
      </main>
      <button
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }}
          className="fixed p-2 text-sm text-black bg-blue-500/25 bottom-1 right-1 rounded scroll-smooth"
          style={{ display: scrollPosition <= 800 ? "none" : "unset" }}
        >
          ^ Top
        </button>
    </div>
  )
}

export default Settings
