import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useRef, useState } from 'react';
import { GET_ALL_METERCODES } from '../../queriesAndMutations/queries/meter.queries';
import { MeterCode } from '../../utils/typedefs';

import { ReactComponent as CopyIcon } from '../../assets/copy-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as SaveIcon } from '../../assets/save-icon.svg';

import { UPDATE_DEVICE_EUI } from '../../queriesAndMutations/mutations/meter.mutations';
import { UPDATE_CUSTOMER } from '../../queriesAndMutations/mutations/invoice.mutations';


const UniqueMeterCode = () => {
  const [searchValues, setSearchValues] = useState<Array<MeterCode>>();
  const meterCodeTableItemCopied = useRef<boolean>(false);


  const [copiedValue, setCopiedValue] = useState<string>("");
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [valueToEdit, setValueToEdit] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const currentDeviceEUI = useRef<string>("")
  const currentCustomer = useRef<string>("")
  const currentCustomerId = useRef<string>("")
  const currentMeterNumber = useRef<string>("")
  const currentRowKey = useRef<string>("")
  const [valueSavedToDB, setValueSavedToDB] = useState<string>("");

  const [updateDeviceEUI] = useMutation<any>(UPDATE_DEVICE_EUI,
    {
      fetchPolicy: "network-only",
      refetchQueries: [
        {
          query: GET_ALL_METERCODES,
        },
      ],
    });
  const [updateCustomer] = useMutation<any>(UPDATE_CUSTOMER,
    {
      refetchQueries: [
        {
          query: GET_ALL_METERCODES,
        },
      ],
    });

    console.log();
  const getAllMeterCodes = useQuery<any>(GET_ALL_METERCODES, {
    onCompleted(data) {
      !searchValues && setSearchValues(data?.getAllMeterCodes)
      searchValues && setSearchValues(searchValues?.flatMap((meterCode: MeterCode) => { return data?.getAllMeterCodes?.filter((meter: MeterCode) => meter._id === meterCode._id) }).flat());

      setValueToEdit("");
      startCopyEditMessageTimer(false);
      currentCustomer.current = "";
      currentDeviceEUI.current = "";
      currentCustomerId.current = "";
      currentMeterNumber.current = "";
      currentRowKey.current = "";

    },
  });


  const changeDeviceEUI = () => {
    updateDeviceEUI({
      variables: {
        "input": {
          "currentDeviceEUI": currentDeviceEUI.current,
          "newDeviceEUI": valueToEdit
          
        }
      },

    }).then((response: any) => {
      if (response.data) {
        setValueSavedToDB(response.data.updateMeterDeviceEUI.deviceEUI)
        setValueToEdit("");
        startCopyEditMessageTimer(false);
      }
    })
      .catch((err: any) => {
        setValueToEdit("");

      });;
  }

  const changeCustomer = () => {
    updateCustomer({
      variables: {
        "input": {
          "currentCustomer": currentCustomer.current,
          "newCustomer": valueToEdit,
          "meterNumber": currentMeterNumber.current
        }
      },

    }).then((response: any) => {
        if (response.data.updateInvoiceCustomer) {
          setValueSavedToDB(response.data.updateInvoiceCustomer[0].customer)

        }
      })
      .catch((err: any) => {
        setValueToEdit("");

      });;
  }



  const handleEditCustomerOrDeviceEUI = (value: string, custId: string, meterNumber: string, rowKey: string, saveToDb: boolean, isDeviceEUI: boolean) => {

    currentCustomerId.current = custId ? custId : "";
    currentMeterNumber.current = meterNumber ? meterNumber : "";
    !isDeviceEUI && (currentRowKey.current = rowKey ? rowKey : "");

    setIsEditing(!isEditing);

    if (!isEditing && value.length && valueToEdit === "") { setValueToEdit(value) }

    if (!saveToDb) {
      isDeviceEUI ? currentDeviceEUI.current = value : currentCustomer.current = value;
      return
    }

    if (saveToDb) {
      if (valueToEdit.length && valueToEdit !== (isDeviceEUI ? currentDeviceEUI.current : currentCustomer.current)) {
        isDeviceEUI ? changeDeviceEUI() : changeCustomer();
        return
      }
      setValueToEdit("")
    }
  }


  const handleSearchChange = (searchValue: any) => {
    setSearchValues(getAllMeterCodes?.data?.getAllMeterCodes?.filter((meterCodeItem: MeterCode) =>
      meterCodeItem.meterNumber.toUpperCase().includes(searchValue.toUpperCase()) || meterCodeItem.uniqueMeterCode.toUpperCase().includes(searchValue.toUpperCase()) ||
      meterCodeItem.customer.toUpperCase().includes(searchValue.toUpperCase()) || meterCodeItem.deviceEUI.toUpperCase().includes(searchValue.toUpperCase())
    ))

  }

  const copyCellDataToClipboard = (valueToCopy: string, customerId?: string, rowKey : string = "") => {
    if (isEditing) { return }

    if(customerId ) {
      currentCustomerId.current = customerId; 
      currentRowKey.current = rowKey; 
    }
    try{
      // Clipboard.writeText will only work over https...
      navigator.clipboard.writeText(valueToCopy);
      meterCodeTableItemCopied.current = true;
      setCopiedValue(valueToCopy);
      startCopyEditMessageTimer(true);
    }
    catch(err: any){
      console.error(err)
    }
  };

  const startCopyEditMessageTimer = (copying: boolean) => {
    setTimeout(() => {
      if (copying) {
        setCopiedValue("");
        meterCodeTableItemCopied.current = false;
        currentCustomerId.current = "";
        currentRowKey.current = "";
        return;
      }
      setValueSavedToDB("");

    }, 1400);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset || window.scrollY);
  };


  const onChange = (event: any) => {
    setValueToEdit((event.target.name === "deviceEUI") ? event.target.value.toUpperCase() : event.target.value);
  };

  const handleCancel = () => {
    setValueToEdit("");
    setIsEditing(false);
    currentCustomerId.current = "";
    currentCustomer.current = "";
    currentDeviceEUI.current = "";
    currentMeterNumber.current = "";
    currentRowKey.current = "";
  };

  return (
    <>
      {/* /////// MeterCode Table ////// */}
      <div className='card flex flex-col items-center'>
      <h2 className="text-xl font-bold text-primary  my-6">MeterCode Details</h2>
        <h1 className='text-xl mt-8 mb-8'>{`Total of ${getAllMeterCodes.loading ? "" : getAllMeterCodes?.data?.getAllMeterCodes?.length} Meters:`}</h1>
        <div>
          <h1 className='text-sm mb-2 '>Search by Customer, DeviceEUI, Meter Number or MeterCode:</h1>
          <input
            placeholder='Search...'
            type={"text"}
            disabled={getAllMeterCodes?.loading}
            className="block w-full mb-12 pr-8 transition border-2 border-gray-300 rounded-md shadow-sm hover:bg-secondary/10 focus:border-secondary focus:ring focus:ring-secondary/50 focus:ring-opacity-50 bg-secondary/5 disabled:cursor-not-allowed"
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>
        <span className='flex flex-row align-center justify-center mb-4'>Click on any value to copy to the clipboard. <CopyIcon className='ml-2' /></span>
        <table className="min-w-full metercode-table mb-6">
          <thead>
            <tr>
              <th className="text-center p-3">Meter Number</th>
              <th className="text-center p-3">MeterCode</th>
              <th className="text-center p-3">DeviceEUI</th>
              <th className="text-center p-3">Customer</th>
            </tr>
          </thead>
          <tbody>
            {getAllMeterCodes?.loading && <tr id='loading'>
              <td>
                Loading MeterCode Data...
              </td>

            </tr>
            }
            {!getAllMeterCodes.loading && searchValues?.map((meterCodeItem: MeterCode) => (

              <tr key={meterCodeItem._id.toString()} className="my-8 odd:bg-secondary/5 hover:bg-secondary/10">
                {/* METER NUMBER */}
                <td  id={meterCodeItem.meterNumber} className='text-center copy-cursor' onClick={(e: any) => { copyCellDataToClipboard(meterCodeItem.meterNumber); }}>

                  <div className="tooltip">  {meterCodeItem.meterNumber}
                    <span className="tooltiptext" style={{ visibility: copiedValue === meterCodeItem.meterNumber ? "visible" : "hidden" }}>Meter Number Copied</span>
                  </div>
                </td>
                {/* UNIQUE METERCODE */}
                <td id={meterCodeItem.uniqueMeterCode} className='text-center copy-cursor ' onClick={(e: any) => { copyCellDataToClipboard(meterCodeItem.uniqueMeterCode); }}>

                  <div className="tooltip">  {meterCodeItem.uniqueMeterCode}
                    <span className="tooltiptext" style={{ visibility: copiedValue === meterCodeItem.uniqueMeterCode ? "visible" : "hidden" }}>MeterCode Copied</span>
                  </div>
                </td>
                {/* DEVICEEUI */}
                <td id={meterCodeItem.deviceEUI} className='text-center cursor-pointer md:px-0'>
                  <div className="tooltip">
                    <div className="cell-data-container flex flex-row">
                      <span className="copy-cursor" onClick={(e: any) => { copyCellDataToClipboard(meterCodeItem.deviceEUI); }}>

                        {isEditing && currentDeviceEUI.current === meterCodeItem.deviceEUI ? <input type="text"
                          name="deviceEUI"
                          className="block w-full text-sm transition border-2 border-gray-300 rounded-md shadow-sm hover:bg-secondary/10 focus:border-secondary focus:ring focus:ring-secondary/50 focus:ring-opacity-50 bg-secondary/5"
                          value={valueToEdit}
                          onChange={onChange}
                        />
                          :
                          valueToEdit && currentDeviceEUI.current === meterCodeItem.deviceEUI ?

                            <span className="save font-bold" >Saving... </span>
                            : meterCodeItem.deviceEUI
                        }

                      </span>

                      {isEditing && currentDeviceEUI.current === meterCodeItem.deviceEUI &&
                        <div className='flex flex-row'>
                          {valueToEdit.length === 16 ? <SaveIcon className='mx-2 save' onClick={() => handleEditCustomerOrDeviceEUI(meterCodeItem.deviceEUI, "", "", meterCodeItem._id, true, true)} />
                            : <SaveIcon className='mx-2 cursor-not-allowed save-disabled' />}
                          <span className='text-red-700 font-medium' onClick={handleCancel}>Cancel</span>

                        </div>
                      }
                      {!isEditing && !currentDeviceEUI.current && <EditIcon className='ml-2' onClick={() => handleEditCustomerOrDeviceEUI(meterCodeItem.deviceEUI, "", "",  meterCodeItem._id, false, true)} />}
                    </div>

                    <span className="tooltiptext" style={{ visibility: valueSavedToDB === meterCodeItem.deviceEUI ? "visible" : "hidden" }}>DeviceEUI Saved</span>
                    <span className="tooltiptext" style={{ visibility: copiedValue === meterCodeItem.deviceEUI ? "visible" : "hidden" }}>DeviceEUI Copied</span>
                  </div>
                </td>
                {/* CUSTOMER NAME */}
                <td id={meterCodeItem._id} className='text-center cursor-pointer md:px-0'>
                  <div className="tooltip">
                    <div className="cell-data-container flex flex-row">
                      <span className="copy-cursor" onClick={(e: any) => { copyCellDataToClipboard(meterCodeItem.customer, meterCodeItem.customerId, meterCodeItem._id); }}>
                        {isEditing && currentRowKey.current === meterCodeItem._id ? <input type="text"
                          name="customerName"
                          className="block w-full text-sm p-1 transition border-2 border-gray-300 rounded-md shadow-sm hover:bg-secondary/10 focus:border-secondary focus:ring focus:ring-secondary/50 focus:ring-opacity-50 bg-secondary/5"
                          value={valueToEdit}
                          onChange={onChange}
                        />
                          :
                          valueToEdit && currentRowKey.current === meterCodeItem._id ?
                            <span className="save font-bold" >Saving... </span>
                            : meterCodeItem.customer
                        }
                      </span>

                      {isEditing && currentRowKey.current === meterCodeItem._id &&
                        <div className='flex flex-row'>
                          {valueToEdit.length > 2 ? <SaveIcon className='mx-2 save' onClick={() => handleEditCustomerOrDeviceEUI(meterCodeItem.customer, meterCodeItem.customerId, meterCodeItem.meterNumber, meterCodeItem._id,true, false)} />
                            : <SaveIcon className='mx-2 cursor-not-allowed save-disabled' />}
                          <span className='text-red-700 font-medium mr-2' onClick={handleCancel}>Cancel</span>

                        </div>
                      }
                      {!isEditing && currentRowKey.current !== meterCodeItem._id &&
                        <EditIcon className='ml-2' onClick={() => handleEditCustomerOrDeviceEUI(meterCodeItem.customer, meterCodeItem.customerId, meterCodeItem.meterNumber, meterCodeItem._id, false, false)} />
                      }

                    </div>
                    <span className="tooltiptext" style={{ visibility: valueSavedToDB === meterCodeItem.customer ? "visible" : "hidden" }}>Customer Saved</span>
                    <span className="tooltiptext" style={{ visibility: copiedValue === meterCodeItem.customer && currentRowKey.current === meterCodeItem._id   ? "visible" : "hidden" }}>Customer Copied</span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }}
          className="fixed p-2 text-sm text-black bg-blue-500/25 bottom-1 right-1 rounded scroll-smooth"
          style={{ display: scrollPosition <= 800 ? "none" : "unset" }}
        >
          ^ Top
        </button>
      </div>
    </>
  )
}

export default UniqueMeterCode